body {
  padding: 10px;
}

em {
  font-weight: bold;
}

.StateRank,
.StateRep {
  border: 1px solid green;
  border-radius: 6px;
  padding: 3px;
  display: inline-block;
  margin-right: 3px;
  margin-top: 3px;
  cursor: pointer;
  user-select: none;
  background-color: #fff;
}

.StateRep {
  border: 1px solid red;
}

.PlayerRank {
  font-weight: bold;
}

.PlayerRank.other {
  color: red;
}

.Players dl:nth-child(odd) {
  background-color: #eff;
}



dl {
  padding: 3px;
  margin: 3px;
}

.disabled {
  color: #ccc;

}

.other {
  color: red;
}

.CutLine {
  display: block;
  margin: 20px 0;
}

.Tips,
.Todos {
  font-size: 0.8em;
}

.ToggleOpenOnly,
.Export span,
.Tips h3 span,
.Strength h3 span {
  margin: 3px;
  font-size: 80%;
  color: #666;
  cursor: pointer;
  user-select: none;
  font-weight: normal;
}

.Todos {
  border-top: 1px solid #ccc;
  margin-top: 40px;
}

.Todos .done {
  text-decoration: line-through;
}

.Error {
  color: red;
}

.Export {
  border: 1px solid #ccc;
  padding: 6px;
  margin: 3px 0;
}

.Export select {
  padding: 3px;
  margin: 3px;
}

.Export textarea {
  min-height: 30em;
  width: 200px;
  display: block;
}

.Export textarea.top32 {
  min-height: 40em;
}

.Export textarea.all {
  min-height: 120em;
}

.Nav {
  margin: 3px;
}

.Nav span {
  margin-right: 10px;
}

.WPlayers {
  display: flex;
  justify-content: space-between;
}

.WPlayers .Top100 {
  width: 48%;
}

.WPlayers .Top100 .ToggleOpenOnly {
  font-size: 0.6em;
}

.WPlayers .Top100.NotCounted h4.warning {
  color: red;
}

.WPlayers .Top100.NotCounted dl {
  opacity: 0.5;
}

.WPlayers dl:nth-child(odd) {
  background-color: #eff;
}

.Finalists dl:nth-child(even) {
  background-color: #eff
}